<template>
  <div id="ae4ce35a-a2c2-42c8-bc26-6ee8643c2683">
    <!-- IMAGE -->
    <br>
    <v-row>
      <v-col v-if="loading" class="d-flex justify-center align-center" cols="12" md="7" lg="7" xl="9">
        <Spinner :size="30" />
      </v-col>
      <v-col v-else-if="images.length > 0" cols="12" md="7" lg="7" xl="9">
        <ImageCarousel
          :images="images"
          :fullscreen="fullscreen"
          @setFullscreen="setFullscreen"
        />
      </v-col>
      <v-col v-else cols="12" md="7" lg="7" xl="9">
        <DefaultImage
          :height="imageHeight"
        />
      </v-col>

      <!-- SUBSCRIPTION INFO CARD -->
      <v-col cols="12" md="5" lg="5" xl="3">
        <SubscriptionInfoCard
          v-if="investorSubscriptionInformation"
          :investorSubscriptionInformation="investorSubscriptionInformation"
          :product="product"
          :investLoading="investLoading"
          @invest="invest"
        />
        <v-skeleton-loader
          v-else
          type="card"
          :height="imageHeight"
          class="rounded-lg"
        ></v-skeleton-loader>
      </v-col>

    </v-row>

    <!-- SUBSCRIPTION TERMS CARD -->
    <v-row>
      <v-col cols="12" md="7" lg="7" xl="9">
        <SubscriptionTermsCard
          :product="product"
          performance="20,00"
          average="2,37"
        />
      </v-col>
    </v-row>

    <!-- MORE DETAILS -->
    <div class="mt-5 more-details">
      <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleOne`)" />
      <p class="mt-5 font-weight-medium" v-html="$t(`${productId}.productInfo.moreDetails.descriptionOneTitle`)" />
      <div class="mt-3 d-flex">
        <p v-html="$t(`${productId}.productInfo.moreDetails.descriptionOne`)" />
        <v-img
          :src="require(`@/assets/img/products/${productId}.jpg`)"
          height="226"
          width="440"
          class="ml-5 rounded-custom"
        />
      </div>
      <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionTwoTitle`)" />
      <p class="mt-3" v-html="$t(`${productId}.productInfo.moreDetails.descriptionTwo`)" />
      <v-expansion-panels
        accordion
        v-model="panel"
        multiple
      >
        <v-expansion-panel :readonly="false">
          <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
            <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleTwo`)" />
            <template v-slot:actions v-if="false">
              <v-icon class="icon">mdi-lock</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="backgroundDark" class="px-0">
            <v-row class="mt-5">
              <v-col class="font-weight-medium">Der Wein</v-col>
              <v-col class="font-weight-medium">Technische Daten</v-col>
              <v-col class="font-weight-medium">Zustand</v-col>
              <v-col class="font-weight-medium">Fakten zur Maison Ruinart</v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Marke</v-col>
              <v-col cols="2">Ruinart</v-col>
              <v-col cols="1" class="grey--text">Füllmenge</v-col>
              <v-col cols="2">1,5 L</v-col>
              <v-col cols="1" class="grey--text">Grade* (A,B,C)</v-col>
              <v-col cols="2">Standard</v-col>
              <v-col cols="1" class="grey--text">Ausstoß/p.a.</v-col>
              <v-col cols="2">150.000 Kisten (E)</v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Modell</v-col>
              <v-col cols="2">Cuveé Tradition</v-col>
              <v-col cols="1" class="grey--text">Format</v-col>
              <v-col cols="2">Magnum</v-col>
              <v-col cols="1" class="grey--text">Status</v-col>
              <v-col cols="2">unbeschädigt, lagerfähig</v-col>
              <v-col cols="1" class="grey--text">Rebfläche</v-col>
              <v-col cols="2">14 ha</v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Segment</v-col>
              <v-col cols="2">Retail</v-col>
              <v-col cols="1" class="grey--text">Verschluss</v-col>
              <v-col cols="2">Kork, natur, Agraffe</v-col>
              <v-col cols="1" class="grey--text">Quelle</v-col>
              <v-col cols="2">Fachhandel</v-col>
              <v-col cols="1" class="grey--text">Gründung</v-col>
              <v-col cols="2">1729</v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Gebinde</v-col>
              <v-col cols="2">Glasflasche</v-col>
              <v-col cols="1" class="grey--text">Produktion</v-col>
              <v-col cols="2">Méthode Champenoise</v-col>
              <v-col cols="1" class="grey--text">Originalzustand</v-col>
              <v-col cols="2"><v-icon size="12" class="ml-1 mt-1">mdi-check</v-icon></v-col>
              <v-col cols="1"></v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Limitierung</v-col>
              <v-col cols="2">keine</v-col>
              <v-col cols="1" class="grey--text">Alkoholgehalt</v-col>
              <v-col cols="2">12,5% / Vol.</v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Produktion</v-col>
              <v-col cols="2">2021</v-col>
              <v-col cols="1" class="grey--text">Lagerfähigkeit</v-col>
              <v-col cols="2">2030</v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Erwerb</v-col>
              <v-col cols="2">2022</v-col>
              <v-col cols="1" class="grey--text">Verkostung</v-col>
              <v-col cols="2">frisch, Zitrus (reif), Pfirsich</v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1" class="grey--text">Lagerort</v-col>
              <v-col cols="2">München, Deutschland</v-col>
              <v-col cols="1" class="grey--text">Rebmaterial</v-col>
              <v-col cols="2">Côte des Blancs, Montagne de Reims</v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1"></v-col>
              <v-col cols="2"></v-col>
              <v-col cols="1" class="grey--text">Assemblage</v-col>
              <v-col cols="2">57% Pinot Noir 40% Chardonnay 3% Meunier</v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
            <v-row class="mt-0 medium-text">
              <v-col cols="1"></v-col>
              <v-col cols="2"></v-col>
              <v-col cols="1" class="grey--text">Reservewein</v-col>
              <v-col cols="2">max. 25% (-2 Jahre)</v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :readonly="false">
          <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
            <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleThree`)" />
            <template v-slot:actions v-if="false">
              <v-icon class="icon">mdi-lock</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="backgroundDark" class="px-0">
            <p class="mt-5">
              <span v-html="$t(`${productId}.productInfo.moreDetails.descriptionFour`)" /><br>
              <span class="xs-text" v-html="$t(`${productId}.productInfo.moreDetails.descriptionFourFooter`)" />
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :readonly="!onBoardingCompleted">
          <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
            <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleFour`)" />
            <template v-slot:actions v-if="!onBoardingCompleted">
              <v-icon class="icon">mdi-lock</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="backgroundDark" class="px-0">
            <p class="mt-5">
              <span v-html="$t(`${productId}.productInfo.moreDetails.descriptionFive`)" /><br>
              <span class="xs-text" v-html="$t(`${productId}.productInfo.moreDetails.descriptionFiveFooter`)" />
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :readonly="!onBoardingCompleted">
          <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
            <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleFive`)" />
            <template v-slot:actions v-if="!onBoardingCompleted">
              <v-icon class="icon">mdi-lock</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="backgroundDark" class="px-0">
            <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionSix`)" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :readonly="!onBoardingCompleted">
          <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
            <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleSix`)" />
            <template v-slot:actions v-if="!onBoardingCompleted">
              <v-icon class="icon">mdi-lock</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="backgroundDark" class="px-0">
            <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionSeven`)" />
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>

      <div class="d-flex mt-2">
        <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleSeven`)" />
        <v-icon v-if="!onBoardingCompleted" class="ml-3">mdi-lock</v-icon>
      </div>
      <div v-if="onBoardingCompleted">
        <div v-if="loading" class="d-flex justify-center">
          <Spinner :size="30" />
        </div>
        <div v-else class="mt-5 d-flex flex-wrap">
          <Document
            v-for="(document, index) in documents"
            :key="index"
            :document="document"
            :index="index"
          />
        </div>
      </div>
      <h3 v-html="$t(`${productId}.productInfo.moreDetails.titleEight`)" class="mt-5" />
      <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionEight`)" />
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import pdpDefaultDocs from '@/config/pdpDefaultDocs';
import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import Spinner from '@/components/Shared/Spinner.vue';

export default {
  name: 'ae4ce35a-a2c2-42c8-bc26-6ee8643c2683',
  components: {
    DefaultImage: lazyLoad('components/Assets/DefaultImage'),
    Document: lazyLoad('components/Marketplace/NewDeals/ProductDetails/ProductDocument'),
    SubscriptionInfoCard: lazyLoad('components/Marketplace/NewDeals/ProductDetails/SubscriptionInfoCard'),
    SubscriptionTermsCard: lazyLoad('components/Marketplace/NewDeals/ProductDetails/SubscriptionTermsCard'),
    ImageCarousel: lazyLoad('components/Marketplace/NewDeals/ProductDetails/ImageCarousel'),
    Spinner,
  },
  props: {
    product: {
      type: Object,
    },
    investorSubscriptionInformation: {
      type: Object,
    },
    investLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [],
      loading: true,
      fullscreen: false,
      onboardingModal: false,
      panel: [],
      documents: clone(pdpDefaultDocs),
      documentLoading: null,
    };
  },
  computed: {
    ...mapGetters('user', [
      'onBoardingCompleted',
    ]),
    imageHeight() {
      return this.fullscreen ? 700 : 456;
    },
    productId() {
      return this.$route.params.product;
    },
  },
  async created() {
    const images = await this.$store.dispatch('assets/getImages', {
      category: 'Supporting',
      assetId: this.product.id,
    });
    if (images.length > 0) {
      this.images = images;
    }
    const documents = await this.$store.dispatch('assets/getDocuments', {
      category: 'ProductDetailPage',
      assetId: this.productId,
    });
    if (documents.length > 0) {
      documents.forEach((item) => {
        this.documents.push({
          documentTemplateId: item.key,
          documentKey: item.documents[0].key,
          title: item.title,
        });
      });
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('onboarding', [
      'setOnboardingModal',
    ]),
    invest() {
      if (this.onBoardingCompleted) {
        this.$emit('invest');
      } else {
        this.setOnboardingModal(true);
      }
    },
    setFullscreen(value) {
      this.fullscreen = value;
    },
  },
};
</script>

<style lang="scss">
#ae4ce35a-a2c2-42c8-bc26-6ee8643c2683 {
  .align-right {
    text-align: right;
  }
  .align-left {
    text-align: left;
  }
  .field-desc {
    font-size: 12px;
  }
  .v-divider {
    margin: .5em 0 1em 0;
  }
  .v-slider {
    margin: 0;
  }
  .xs-text {
    font-size: 10px;
  }
  .small-text {
    font-size: 12px;
  }
  .medium-text {
    font-size: 13px;
  }
  .big-text {
    font-size: 16px;
  }
  .v-skeleton-loader__image.v-skeleton-loader__bone {
    height: 400px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .more-details {
    text-align: justify;
  }
  .tooltip-text {
    font-size: 50px !important;
    opacity: 1 !important;
    display: block !important;
    color: red !important;
  }
  .v-expansion-panel-content__wrap {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .v-expansion-panel-header__icon {
    margin-left: 20px !important;
  }
  .v-expansion-panel-header>:not(.v-expansion-panel-header__icon) {
    flex-grow: 0 !important;
  }
  .v-expansion-panel:not(:first-child):after {
    border-top: 0px !important;
  }
  .v-expansion-panel:before {
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0 !important;
  }
  .rounded-custom {
    border-radius: 10px !important;
  }
  .grey--text {
    white-space: initial;
    word-wrap: break-word;
  }
}
</style>
